import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LayOut from "@/views/layout/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home/index",
    meta: { name: "首页" },
  },

  {
    path: "/home",
    component: LayOut,
    redirect: "/home/index",
    meta: { name: "首页" },
    children: [
      {
        path: "index",
        name: "homeIndex",
        component: () =>
          import(/* webpackChunkName: "homeIndex" */ "@/views/home/index.vue"),
      },
    ],
  },

  // 走进协会
  {
    path: "/intoAssociation",
    component: LayOut,
    redirect: "/intoAssociation/introduction",
    meta: { name: "走进协会" },
    children: [
      {
        path: "introduction",
        name: "introduction",
        component: () =>
          import(
            /* webpackChunkName: "introduction" */ "@/views/intoAssociation/introduction/index.vue"
          ),
        meta: { name: "协会简介" },
      },
      {
        path: "rightsAndObligations",
        name: "rightsAndObligations",
        component: () =>
          import(
            /* webpackChunkName: "rightsAndObligations" */ "@/views/intoAssociation/rightsAndObligations/index.vue"
          ),
        meta: { name: "会员权利与义务" },
      },
      {
        path: "mainLeader",
        name: "mainLeader",
        component: () =>
          import(
            /* webpackChunkName: "mainLeader" */ "@/views/intoAssociation/mainLeader/index.vue"
          ),
        meta: { name: "主要领导" },
      },
      {
        path: "organization",
        name: "organization",
        component: () =>
          import(
            /* webpackChunkName: "organization" */ "@/views/intoAssociation/organization/index.vue"
          ),
        meta: { name: "组织机构" },
      },
      // {
      //   path: "vipList",
      //   name: "vipList",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "vipList" */ "@/views/intoAssociation/vipList/index.vue"
      //     ),
      //   meta: { name: "会员名录" },
      // },
    ],
  },

  // 新闻资讯
  {
    path: "/newsInformation",
    component: LayOut,
    redirect: "/newsInformation/associationNews",
    meta: { name: "最新资讯" },
    children: [
      {
        path: "associationNews",
        name: "associationNews",
        component: () =>
          import(
            /* webpackChunkName: "associationNews" */ "@/views/newsInformation/associationNews/index.vue"
          ),
        meta: { name: "协会动态" },
      },
      {
        path: "industryNews",
        name: "industryNews",
        component: () =>
          import(
            /* webpackChunkName: "industryNews" */ "@/views/newsInformation/industryNews/index.vue"
          ),
        meta: { name: "行业动态" },
      },
      {
        path: "personNews",
        name: "personNews",
        component: () =>
          import(
            /* webpackChunkName: "personNews" */ "@/views/newsInformation/personNews/index.vue"
          ),
        meta: { name: "会员动态" },
      },
    ],
  },

  // 职业技能等级认定
  {
    path: "/skillLevel",
    component: LayOut,
    redirect: "/skillLevel/evaluationProject",
    meta: { name: "职业技能等级认定" },
    children: [
      {
        path: "evaluationProject",
        name: "evaluationProject",
        component: () =>
          import(
            /* webpackChunkName: "evaluationProject" */ "@/views/skillLevel/evaluationProject/index.vue"
          ),
        meta: { name: "评价项目简介" },
      },
      {
        path: "levelThat",
        name: "levelThat",
        component: () =>
          import(
            /* webpackChunkName: "levelThat" */ "@/views/skillLevel/levelThat/index.vue"
          ),
        meta: { name: "等级认定公告栏" },
      },
      {
        path: "certificateQuery",
        name: "certificateQuery",
        component: () =>
          import(
            /* webpackChunkName: "certificateQuery" */ "@/views/skillLevel/certificateQuery/index.vue"
          ),
        meta: { name: "职业技能证书查询" },
      },
      {
        path: "certificateDownload",
        name: "certificateDownload",
        component: () =>
          import(
            /* webpackChunkName: "certificateQuery" */ "@/views/skillLevel/certificateDownload/index.vue"
          ),
        meta: { name: "准考证下载" },
      },
      {
        path: "fileDownload",
        name: "fileDownload",
        component: () =>
          import(
            /* webpackChunkName: "fileDownload" */ "@/views/skillLevel/fileDownload/index.vue"
          ),
        meta: { name: "相关文件下载" },
      },
    ],
  },

  // 协会公告
  {
    path: "/announcement",
    component: LayOut,
    redirect: "/announcement/gggs",
    meta: { name: "协会公告" },
    children: [
      {
        path: "gggs",
        name: "gggs",
        component: () =>
          import(
            /* webpackChunkName: "gggs" */ "@/views/announcement/gggs/index.vue"
          ),
        meta: { name: "公告公示" },
      },
      {
        path: "xxgk",
        name: "xxgk",
        component: () =>
          import(
            /* webpackChunkName: "xxgk" */ "@/views/announcement/xxgk/index.vue"
          ),
        meta: { name: "信息公开" },
      },
      {
        path: "gzts",
        name: "gzts",
        component: () =>
          import(
            /* webpackChunkName: "gzts" */ "@/views/announcement/gzts/index.vue"
          ),
        meta: { name: "工作提示" },
      },
    ],
  },

  // 会员登录
  // {
  //   path: "/vipLogin",
  //   component: LayOut,
  //   redirect: "/vipLogin/index",
  //   meta: { name: "会员登录" },
  //   children: [
  //     {
  //       path: "index",
  //       name: "vipLogin",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "vipLogin" */ "@/views/vipLogin/index.vue"
  //         ),
  //       meta: { name: "" },
  //     },
  //   ],
  // },

  // 相关链接
  {
    path: "/relatedLinks",
    component: LayOut,
    redirect: "/relatedLinks/index",
    meta: { name: "相关链接" },
    children: [
      {
        path: "index",
        name: "relatedLinks",
        component: () =>
          import(
            /* webpackChunkName: "relatedLinks" */ "@/views/relatedLinks/index.vue"
          ),
        meta: { name: "" },
      },
    ],
  },

  {
    path: "/joinUs",
    component: LayOut,
    redirect: "/joinUs/index",
    meta: { name: "联系我们" },
    children: [
      {
        path: "index",
        name: "joinUs",
        component: () =>
          import(/* webpackChunkName: "joinUs" */ "@/views/joinUs/index.vue"),
        meta: { name: "" },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
