
import Spinner from "@/components/Spinner";
import { useElementPlusTheme } from "use-element-plus-theme/dist/bundle.umd";
import { defineComponent, reactive, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { NavObj, UrlParam } from "@/types";
export default defineComponent({
  components: {
    Spinner,
  },
  setup() {
    // 全局自定义主题样式
    useElementPlusTheme("#4077d4");

    const router = useRouter();
    // 从路由定义中提出需要的数据
    let routeList = router.options.routes.map((item) => ({
      name: item.meta ? item.meta.name : "",
      path: item.path,
      active: false,
    }));
    // 一级路由的显示，要剔除掉"/"、"/joinUs"
    routeList = routeList.filter(
      (item) => item.path != "/" && item.path != "/joinUs"
    );
    // console.log(routeList);

    /* [
      { name: "首页", path: "/home", active: false },
      { name: "走进协会", path: "/intoAssociation", active: false },
      { name: "新闻资讯", path: "/newsInformation", active: false },
      { name: "职业技能等级认定", path: "/skillLevel", active: false },
      { name: "会员登录", path: "/login", active: false },
      { name: "相关链接", path: "/relatedLinks", active: false },
    ]; */

    // 一级路由列表
    const oneRouteList = reactive(routeList);

    // 点击tab时，激活样式，跳转路由
    const clickTab = (item: NavObj) => {
      oneRouteList.forEach((item) => (item.active = false));

      item.active = true;
      router.push(item.path);
    };

    // 刷新浏览器时，反向检测tab标签是否要激活
    watch(
      () => router.currentRoute.value.fullPath,
      (newVal, oldVal) => {
        oneRouteList.forEach((item) => {
          if (newVal.includes(item.path)) {
            item.active = true;
          } else {
            item.active = false;
          }
        });
      }
    );

    const getUrlParams = (url: string = window.location.href) => {
      let sHref = url;
      let args = sHref.split("?");
      if (args[0] === sHref) {
        return {};
      }
      let hrefarr: string[] = args[1].split("#")[0].split("&");
      let hrefarr1: string[][] = [];

      var obj: UrlParam = {};
      for (let i = 0; i < hrefarr.length; i++) {
        hrefarr1[i] = hrefarr[i].split("=");
        obj[hrefarr1[i][0]] = hrefarr1[i][1];
      }
      return obj;
    };

    onMounted(() => {
      const viewWidth = window.innerWidth; //获取可视区域宽度
      const viewHeight = window.innerHeight; //获取可视区域高度

      const mainContent = document.getElementsByTagName(
        "html"
      )[0] as HTMLElement;
      console.log("可视窗口", viewWidth, viewHeight);
      // 如果屏幕小于1660时，则按比例缩放
      if (viewWidth < 1660) {
        mainContent.style.transformOrigin = "center top"; // 更改原点的位置
        // 主要内容区根据视口大小，整体缩放
        mainContent.style.transform = `scale(${viewWidth / 1690})`;
        // 计算新高度，不然底部会有很多空白
        let newHeight = viewHeight * (viewWidth / 1690);
        mainContent.style.height = newHeight + "px";
      }

      // 如果url上携带dataType参数，则存入localStorage
      let paramsObj: UrlParam = getUrlParams();
      localStorage.setItem("dataType", paramsObj.dataType || "prod");
    });

    return {
      oneRouteList,
      clickTab,
    };
  },
});
