import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-292fbb7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "right-main" }
const _hoisted_3 = { class: "left-nav" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.titleName), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.twoPathList, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(['item-nav', item.active ? 'active': '']),
            key: item.path,
            onClick: ($event: any) => (_ctx.clickNav(item))
          }, _toDisplayString(item.name), 11, _hoisted_5))
        }), 128))
      ], 512), [
        [_vShow, _ctx.titleName != '首页']
      ]),
      _createVNode(_component_router_view)
    ])
  ]))
}