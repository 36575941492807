
import { defineComponent, watch, ref } from "vue";
import { useRouter } from "vue-router";
import { NavObj } from "@/types";

export default defineComponent({
  name: "LayoutIndex",
  setup() {
    const router = useRouter();

    // 定义数据
    const titleName = ref(""); // 侧边栏标题名称
    const twoPathList = ref<NavObj[]>([]); // 二级路由数据
    const onePath = ref(""); // 当前的一级路由

    // 所有的路由取出
    const allRouteList = router.options.routes;
    // 监测路由的变化，取出当前的二级路由
    watch(
      () => router.currentRoute.value.fullPath,
      (newVal, oldVal) => {
        // 取出一级路由
        onePath.value = newVal.split("/")[1];
        const oldOnePath = oldVal?.split("/")[1];

        // 如果一级路由相等，则跳过
        if (onePath.value === oldOnePath) {
          return;
        }

        // 取出二级路由列表
        let twoPathObj = allRouteList.filter((item) =>
          item.path.includes(onePath.value)
        )[0];

        titleName.value = twoPathObj.meta?.name as string; // 一级路由的标题显示

        // 如下没有二级菜单
        if (
          titleName.value == "首页" ||
          titleName.value == "相关链接" ||
          titleName.value == "联系我们" ||
          titleName.value == "会员登录"
        ) {
          twoPathList.value = [];
          return;
        } else {
          if (twoPathObj.children) {
            let twoPath2: NavObj[] = twoPathObj.children.map((item) => ({
              name: item.meta?.name as string,
              path: item.path,
              active: newVal.split("/")[2] == item.path ? true : false,
            }));
            twoPathList.value = twoPath2;
          }
        }
        if (newVal.includes("?")) {
          const temp = newVal.split("/")[2].split("?")[0];
          twoPathList.value.forEach((item) => {
            if (item.path == temp) {
              item.active = true;
            } else {
              item.active = false;
            }
          });
        }
      },
      { immediate: true }
    );

    // 点击侧边栏导航，激活相应样式，跳转页面
    const clickNav = (navObj: NavObj) => {
      twoPathList.value.forEach((item) => {
        if (item.path == navObj.path) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
      router.push(`/${onePath.value}/${navObj.path}`);
    };

    return {
      onePath,
      titleName,
      twoPathList,
      clickNav,
    };
  },
});
